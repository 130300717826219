import emailjs from 'emailjs-com';
import React, { useEffect, useRef } from 'react';
import signupThumb from '../../assets/images/signup-thumb.png';
import HubspotContactForm from '../HomeOne/HubSpotForm';

function SponserHomeEight({ className }) {
  const form = useRef();

//   useEffect(() => {
//     // Load HubSpot form script
//     const script = document.createElement('script');
//     script.charset = 'utf-8';
//     script.type = 'text/javascript';
//     script.src = '//js.hsforms.net/forms/embed/v2.js';
//     document.head.appendChild(script);

//     script.onload = () => {
//       // Create HubSpot form
//       window.hbspt.forms.create({
//         region: 'na1',
//         portalId: '44574527',
//         formId: '17c47a78-2f80-4f38-ac44-46e89b480f36',
//       });
//     };

//     return () => {
//       // Cleanup script on component unmount
//       document.head.removeChild(script);
//     };
//   }, []); // Empty dependency array ensures the effect runs only once on mount

  function sendEmail(e) {
    e.preventDefault();
    console.log('sendEmail called.');
    emailjs
      .sendForm('service_nihal123', 'template_e9jljae', form.current, 'hqU8DcD_5Vics_DFX')
      .then(
        (result) => {
          console.log(result);
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <>
      <div className={`appie-signup-area ${className || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-signup-box">
                {/* HubSpot form container */}
                {/* <div id="hubspotForm"></div> */}

                <span>Bulk Order / Wholesale Pricing</span>
                <h3 className="title">Fill form to connect on whatsapp now!</h3>
                {/* <form ref={form} onSubmit={sendEmail}>
                  <label>Name</label>
                  <input type="text" name="user_name" />
                  <label>Email</label>
                  <input type="email" name="user_email" />
                  <label>Message</label>
                  <textarea name="message" />
                  <input type="submit" value="Send" />
                </form> */}

                <HubspotContactForm/>

                <div className="thumb">
                  <img src={signupThumb} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SponserHomeEight;
