import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                    Emcce Sons is a outlet for Diaries and Journals in Bangalore. We
                                    customise diaries as per your requirements <br />
                                    Since 1976.
                                </p>
                                <a href="#">
                                    Visit Outlet <i className="fal fa-arrow-right" />
                                </a>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-whatsapp" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Products</h4>
                                <ul>
                                    <li>
                                        <Link to="/contact">Eagle Diaries 2024</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Leather Journals</Link>
                                    </li>
                                    <li>
                                        <a href="/contact">Leather Diaries</a>
                                    </li>
                                    <li>
                                        <Link to="/contact">Organizers</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Pocket Diaries</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Undated Diaries</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Links</h4>
                                <ul>
                                    <li>
                                        <a to="/contact">Featured Products</a>
                                    </li>
                                    <li>
                                        <a href="/contact">About Us</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Customization</a>
                                    </li>
                                    <li>
                                        <a href="/contact">FAQ's</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Contact Us</a>
                                    </li>
                                    <li>
                                        <a href="/contact">Buy Now</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Contact</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope" />
                                            emceesonseagle@gmail.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone" /> +(91) 9343716059
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> 29,Sri Krishna
                                            Building, Avenue Road, Bengaluru.
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fas fa-phone" /> Call Now
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="https://api.whatsapp.com/send/?phone=919019716059&text=Hi+i%27m+looking+to+buy+new+year+diaries&type=phone_number&app_absent=0">
                                                <i className="fab fa-whatsapp" /> Whatsapp Now
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>Copyright © 2024 Emcee Sons.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
