// import React from "react";
// import {Helmet} from "react-helmet";
// import {hbspt} from "react-hubspot-form";

// class Application extends React.Component {
//   render () {
//     return (
//         <div className="application">
//             <Helmet>
//             <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
// <script>
//   hbspt.forms.create({
//     region: "na1",
//     portalId: "44574527",
//     formId: "b6154c04-9bdf-474b-807d-36bafd2364b9"
//   });
// </script>
//             </Helmet>
//         </div>
//     );
//   }
// };

import React, {useEffect} from "react";

const HubspotContactForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '44574527',
                    formId: 'da195e3f-4e58-48b8-93df-e3e7140d0910',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );

}

export default HubspotContactForm;