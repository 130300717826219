import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

function TestimonialHomeOne({ className }) {
    return (
        <section
            className={`appie-testimonial-area pt-100 pb-160 ${className || ''}`}
            id="testimonial"
        >
            <div className="container">
                <script
                    src="https://static.elfsight.com/platform/platform.js"
                    data-use-service-core
                    defer
                ></script>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="elfsight-app-9379668a-7b64-410a-9c92-4e042dd29f3c"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
