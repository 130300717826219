import React from 'react';
import teamOne from '../../assets/images/featured-prod-1.png';
import teamTwo from '../../assets/images/featured-prod-2.png';
import teamThree from '../../assets/images/featured-prod-3.png';
import teamFour from '../../assets/images/featured-prod-4.png';

function TeamHomeOne({ className }) {
    return (
        <>
            <section className={`appie-team-area pt-90 pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Our Featured Products</h3>
                                <p>Eagle Diaries 2024 Edition</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamOne} alt="" />
                                    <ul>
                                        <li>
                                            <a href="https://wa.link/fkdvht">
                                                <i className="fab fa-whatsapp" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:9343716059">
                                                <i className="fas fa-phone" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Journals</h5>
                                    <span>Undated Lines</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="thumb">
                                    <img src={teamTwo} alt="" />
                                    <ul>
                                        <li>
                                            <a href="https://wa.link/vmqkbv">
                                                <i className="fab fa-whatsapp" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:9343716059">
                                                <i className="fas fa-phone" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Wings Diaries</h5>
                                    <span>Small and Easy To carry</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="thumb">
                                    <img src={teamThree} alt="" />
                                    <ul>
                                        <li>
                                            <a href="https://wa.link/a5ks4m">
                                                <i className="fab fa-whatsapp" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:9343716059">
                                                <i className="fas fa-phone" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Perfect Diary</h5>
                                    <span> The perfect yearlong companion</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="thumb">
                                    <img src={teamFour} alt="" />
                                    <ul>
                                        <li>
                                            <a href="https://wa.link/yo7yxn">
                                                <i className="fab fa-whatsapp" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:9343716059">
                                                <i className="fas fa-phone" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="content text-center">
                                    <h5 className="title">Project Diary</h5>
                                    <span>Custom Leather Stich</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="team-btn text-center mt-50">
                                <a className="main-btn" href="https://store.newyeardiaries.com/">
                                    {' '}
                                    Buy Now <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TeamHomeOne;
