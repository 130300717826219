import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
import Drawer from '../Mobile/Drawer';
import Forms from './Forms';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            {/* <HeaderNews drawer={drawer} action={drawerAction.toggle} /> */}
            {/* <HeroNews
                title="Contact Us"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/news', title: 'Contact Us' },
                ]}
            /> */}
            <HomeOneHeader action={null} />
            <Forms />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Contact;
