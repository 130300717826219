import React from 'react';
import Routes from './Routes';

// The rest of your code follows...


function App() {
    return <Routes />;
}

export default App;
